import { z } from "zod";

/**
 * Enum defining the possible pattern classifications for rumors
 */
export const rumorTagSchema = z
  .enum([
    "MAX",
    "MIN",
    "UP_TREND",
    "DOWN_TREND",
    "LARGE_SPIKE",
    "LARGE_DROP",
    "THRESHOLD_CROSS",
    "PLATEAU",
    "VOLATILITY",
    "CORRELATION",
    "DIVERGENCE",
    "CONSECUTIVE_GROWTH",
    "CONSECUTIVE_DECLINE",
    "RECOVERY",
    "ACCELERATION",
  ])
  .describe(`Pattern classification for the rumor.

Time-Pattern Tags:
- MAX: Identifies peak value in a time series
- MIN: Identifies lowest value in a time series
- UP_TREND: Consistent upward movement over a defined period
- DOWN_TREND: Consistent downward movement over a defined period

Change Magnitude Tags:
- LARGE_SPIKE: Sudden significant increase (>25% in a single period)
- LARGE_DROP: Sudden significant decrease (>25% in a single period)
- THRESHOLD_CROSS: Value crossing an important round number or milestone

Stability Tags:
- PLATEAU: Stable period with <10% variation
- VOLATILITY: Period with high variation between adjacent time points

Relationship Tags:
- CORRELATION: Similar directional movement between different metrics
- DIVERGENCE: Different directional movement between typically related metrics

Pattern Tags:
- CONSECUTIVE_GROWTH: Multiple sequential periods of increase
- CONSECUTIVE_DECLINE: Multiple sequential periods of decrease
- RECOVERY: Return toward previous levels after decline
- ACCELERATION: Increasing rate of change in same direction`);

export const dashboardRumorGenerationResponseSchema = z.object({
  text: z.string(),
  tag: rumorTagSchema,
  relevantData: z.array(
    z.object({
      epoch: z.object({
        timestamp: z.number().int().describe("Unix timestamp in milliseconds"),
        unit: z.enum(["millisecond", "second", "minute", "hour", "day", "week", "month", "year"]),
      }),
      dataType: z
        .string()
        .describe(
          "Type of data being selected (e.g., 'price', 'volume', 'user_count'), typically sourced from the query/highchart config.",
        ),
      value: z.string().describe("The actual value at this timestamp"),
      metadata: z.record(z.string(), z.string()).optional().describe("Any additional contextual information"),
    }),
  ),
});

export type RumorTag = z.infer<typeof rumorTagSchema>;
export type DashboardRumorGenerationResponse = z.infer<typeof dashboardRumorGenerationResponseSchema>;
