import { z } from "zod";

export const scoreChains = [
  "aptos",
  "arbitrum",
  "avalanche",
  "axelar",
  "base",
  "blast",
  "ethereum",
  "flow",
  "kaia",
  "near",
  "optimism",
  "polygon",
  "sei",
  "solana",
  "thorchain",
  "boba",
] as const;

export const scoreableChains = [...scoreChains, "sei-evm", "flow-evm"] as const;

export const scoreChainSchema = z.enum(scoreChains, {
  errorMap: () => {
    return { message: "invalid chain" };
  },
});

export const scoreableChainSchema = z.enum(scoreableChains, {
  errorMap: () => {
    return { message: "invalid scoreable chain" };
  },
});

const snowflakeScoreSchema = z.object({
  tokensScore: z.number(),
  totalScore: z.number(),
  govScore: z.number(),
  activityScore: z.number(),
  address: z.string(),
  blockchain: z.string(),
  scoreVersion: z.coerce.number(),
  nftsScore: z.number(),
  defiScore: z.number(),
  scoreDate: z.coerce.date(),
});

export const cacheKeyForChainAndAddress = (chain: ScoreableChain, address: string) => {
  if (isLowercaseAddress(chain)) {
    return `scores:${chain.toLowerCase()}:${address.toLowerCase()}`;
  }
  return `scores:${chain.toLowerCase()}:${address}`;
};

export const isLowercaseAddress = (chain: ScoreableChain): boolean => {
  return chain !== "solana";
};

export type ScoreChain = z.infer<typeof scoreChainSchema>;
export type ScoreableChain = z.infer<typeof scoreableChainSchema>;
export type SnowflakeScore = z.infer<typeof snowflakeScoreSchema>;
