import { z } from "zod";
import { adhocPaymentAction, manualPaymentItemDB } from "../adhoc-payments/adhoc";
import { schema as questSchema } from "../quest";
import { chainIdSchema, rewardSourceTypeSchema } from "./rewards";

export const manualPaymentInfoSchema = z.object({
  manifest: adhocPaymentAction,
  payment: manualPaymentItemDB,
});

export const schema = z.lazy(() =>
  z.object({
    id: z.string().uuid(),
    userId: z.string().uuid(),
    profileId: z.union([z.string().uuid(), z.literal("default")]),
    chain: chainIdSchema,
    currency: z.string(),
    tokenEarned: z.coerce.number(),
    tokenClaimed: z.coerce.number(),
    tokenClaimable: z.coerce.number(),
    usdEarned: z.coerce.number(),
    usdClaimed: z.coerce.number(),
    usdClaimable: z.coerce.number().catch(0),
    RewardSourceType: rewardSourceTypeSchema,
    name: z.string().nullable().optional(),
    slug: z.string().nullable().optional(),
    rewardSourceId: z.string(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    kycStatus: z.enum(["CLAIMABLE", "KYC_REQUIRED"]).default("CLAIMABLE"),
    quest: questSchema.optional().nullable(),
    manualReward: manualPaymentInfoSchema.optional(),
  }),
);

export const claimRewardSchema = z.object({
  userRewardsRollupId: z.string(),
  address: z.string(),
});

export const paymentRecordSchema = z.object({
  id: z.string(),
  chain: chainIdSchema,
  network: z.string(),
  status: z.union([
    z.literal("CREATED"),
    z.literal("PENDING"),
    z.literal("COMPLETED"),
    z.literal("FAILED"),
    z.literal("QUEUED"),
    z.literal("UNRESOLVED"),
    z.literal("QUEUED"),
  ]),
  recipientAddress: z.string(),
  txID: z.string().nullable(),
  amount: z.coerce.number(),
  usdAmount: z.coerce.number(),
  rewardSourceType: rewardSourceTypeSchema,
  rewardSourceId: z.string(),
  currency: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

// Summary of rewards earned by a user
export const ecoRewardTotalSchema = z.object({
  usdEarned: z.number(),
  usdClaimed: z.number(),
  tokenEarned: z.number(),
  tokenClaimed: z.number(),
});

export const rewardTotalsSchema = z.object({
  userId: z.string(),
  lifetimeUsdTotal: z.number(),
  lifetimeUsdClaimed: z.number(),
  usdClaimableTotal: z.number(),
  lifetimeRewards: z.record(z.record(ecoRewardTotalSchema)),
});

export const paymentHistorySchema = z.object({
  paymentRecord: paymentRecordSchema,
  questSlug: z.string().nullable(),
  title: z.string().nullable(),
});

export const rewardOverviewSchema = z.object({
  userRewards: z.array(schema),
  paymentHistory: z.array(paymentHistorySchema),
  rewardTotals: rewardTotalsSchema,
});

export type PaymentHistory = z.infer<typeof paymentHistorySchema>;
export type UserRewards = z.infer<typeof schema>;
export type ClaimReward = z.infer<typeof claimRewardSchema>;
export type PaymentRecord = z.infer<typeof paymentRecordSchema>;
export type RewardTotals = z.infer<typeof rewardTotalsSchema>;
export type EcoRewardTotal = z.infer<typeof ecoRewardTotalSchema>;
export type RewardOverview = z.infer<typeof rewardOverviewSchema>;
