import { z } from "zod";
import type { Identity } from "../utils/identity";
import { type Decimal, decimalSchema } from "../utils/zod";
import type { ZodSchema } from "../utils/zod";
import { delegationDailyArchiveSchema } from "./delegation-daily-archive";
import { grantDailyArchiveSchema } from "./grant-daily-archive";

export type PartnerDealDailyArchive = {
  delegation: {
    id: string;
    delegationId: string;
    aprCurrent: Decimal | null;
    validatorCommissionCurrent: Decimal | null;
    tokenPriceCurrent: Decimal | null;
    tokenAmountCurrent: Decimal | null;
    estValueUsd: Decimal;
    createdAt: Date;
    dateOfCreation: string;
    dailyArchiveId: string;
  } | null;
  grant: {
    id: string;
    grantId: string;
    tokenPriceCurrent: Decimal | null;
    tokenAmountCurrent: Decimal | null;
    estValueUsd: Decimal;
    createdAt: Date;
    dateOfCreation: string;
    dailyArchiveId: string;
  } | null;
  id: string;
  dealId: string;
  createdAt: Date;
  dateOfCreation: string;
};

export type BaseManifest = { dateOfUpdate?: Date; tokenPriceCurrent?: Decimal };
export type GrantManifest = BaseManifest;
export type DelegationManifest = Identity<BaseManifest & { aprCurrent: Decimal }>;

export interface CombinedManifest {
  grant?: GrantManifest;
  delegation?: DelegationManifest;
  dateOfUpdate?: Date;
}

export type PartialCombinedManifest = {
  grant?: Partial<GrantManifest>;
  delegation?: Partial<DelegationManifest>;
  dateOfUpdate?: Date;
};

export type PartnerDealDailyArchiveNew = Omit<PartnerDealDailyArchive, "id" | "createdAt">;

const baseManifestSchema = z.object({
  dateOfUpdate: z.coerce.date().optional(),
  tokenPriceCurrent: decimalSchema.optional(),
});
const grantManifestSchema = baseManifestSchema.extend({
  dateOfUpdate: z.coerce.date().optional(),
});
const delegationManifestSchema = baseManifestSchema.extend({
  aprCurrent: decimalSchema.optional(),
});

export const partnerDealDailyArchiveManifestSchema = z.object({
  dateOfUpdate: z.coerce.date().optional(),
  grant: grantManifestSchema.optional(),
  delegation: delegationManifestSchema.optional(),
});

export const partnerDealDailyArchiveSchema = z.object({
  id: z.string().uuid(),
  dealId: z.string().uuid(),
  createdAt: z.coerce.date(),
  dateOfCreation: z.string(),
  delegation: delegationDailyArchiveSchema.nullable(),
  grant: grantDailyArchiveSchema.nullable(),
}) satisfies ZodSchema<PartnerDealDailyArchive>;

export const partnerDealDailyArchiveCreateSchema = partnerDealDailyArchiveSchema.omit({
  id: true,
  createdAt: true,
}) satisfies ZodSchema<PartnerDealDailyArchiveNew>;
