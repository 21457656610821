import { z } from "zod";
import type { Identity } from "../utils/identity";
import type { ZodSchema } from "../utils/zod";

export type Chain = {
  id: string;
  name: string;
  slug: string;
  testnetChainId: string | null;
  mainnetChainId: string | null;
  testnetRpcUrl: string | null;
  mainnetRpcUrl: string | null;
  isRawSigned: boolean;
  createdAt: Date;
  updatedAt: Date;
  blockExplorerTxUrl: string | null;
};

export type ChainNew = Identity<
  Pick<Chain, "name" | "slug"> & {
    testnetChainId?: string | null;
    mainnetChainId?: string | null;
    testnetRpcUrl?: string | null;
    mainnetRpcUrl?: string | null;
    isRawSigned?: boolean;
    blockExplorerTxUrl?: string | null;
  }
>;

export const chainSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  slug: z.string().min(1),
  testnetChainId: z.string().nullable(),
  mainnetChainId: z.string().nullable(),
  testnetRpcUrl: z.string().nullable(),
  mainnetRpcUrl: z.string().nullable(),
  isRawSigned: z.boolean().default(false),
  blockExplorerTxUrl: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
}) satisfies ZodSchema<Chain>;

export const chainCreateSchema = z.object({
  name: z.string().min(1),
  slug: z.string().min(1),
  testnetChainId: z.string().nullable().optional(),
  mainnetChainId: z.string().nullable().optional(),
  testnetRpcUrl: z.string().nullable().optional(),
  mainnetRpcUrl: z.string().nullable().optional(),
  isRawSigned: z.boolean().default(false).optional(),
  blockExplorerTxUrl: z.string().nullable().optional(),
}) satisfies ZodSchema<ChainNew>;

export type ChainUpdate = Identity<ChainNew & { id: string }>;

export const chainUpdateSchema = chainCreateSchema.extend({
  id: z.string().uuid(),
}) satisfies ZodSchema<ChainUpdate>;
