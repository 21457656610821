import { cn } from "~/utils/cn";

export const TokenIcon = ({ symbol, className }: { symbol: string; className?: string }) => {
  return (
    <img
      src={`https://res.cloudinary.com/dsr37ut2z/image/upload/v1719336378/assets/currencies/${symbol}.svg`}
      alt={symbol}
      className={cn("h-6 w-6", className)}
    />
  );
};
