import { z } from "zod";
import { chatMessageSchema } from "./ai-agent";

export const aiChatLogSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  userId: z.string().uuid(),
  agentId: z.string().uuid(),
  input: z.array(chatMessageSchema),
  output: chatMessageSchema,
});

export const aiMessageSchema = z.object({
  id: z.string().uuid(),
  messageContents: chatMessageSchema,
});

export const aiChatSchema = z.object({
  id: z.string().uuid(),
  title: z.string().optional(),
  messages: z.array(aiMessageSchema),
});

export const createAiChatSchema = z.object({
  title: z.string().optional(),
  identifier: z.string().uuid(),
  clientType: z.enum(["USER", "ANONYMOUS"]),
});

export const addAiMessageSchema = z.object({
  chatId: z.string().uuid(),
  identifier: z.string().uuid(),
  clientType: z.enum(["USER", "ANONYMOUS"]),
  messageContents: chatMessageSchema,
  agentVersionId: z.string().uuid(),
});

export type AiChatLog = z.infer<typeof aiChatLogSchema>;
export type CreateAiChatLog = Omit<AiChatLog, "id" | "createdAt" | "updatedAt" | "user" | "agent">;

export type AiChat = z.infer<typeof aiChatSchema>;
export type CreateAiChat = z.infer<typeof createAiChatSchema>;

export type AiMessage = z.infer<typeof aiMessageSchema>;
export type AddAiMessage = z.infer<typeof addAiMessageSchema>;
