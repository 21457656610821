import { z } from "zod";

export const tweetSchema = z.object({
  id: z.string(),
  text: z.string(),
  author_id: z.string(),
  conversation_id: z.string(),
  created_at: z.string(),
  clean_url: z.string(),
});

export type Tweet = z.infer<typeof tweetSchema>;
