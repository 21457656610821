import { z } from "zod";

// Flexible tag system that can contain any metadata
export const tagsSchema = z.record(z.string(), z.union([z.string(), z.number(), z.boolean(), z.date()])).catch({});

export type Tags = z.infer<typeof tagsSchema>;

// Base rumors - atomic pieces of information
export const rumorSchema = z.object({
  id: z.string().uuid(),
  type: z.string(),
  typeId: z.string(),
  text: z.string(),
  version: z.number().default(1),
  tags: tagsSchema,
  createdAt: z.coerce.date(),
});

export const createRumorSchema = rumorSchema.omit({
  id: true,
  version: true,
  createdAt: true,
});

export type Rumor = z.infer<typeof rumorSchema>;
export type CreateRumor = z.infer<typeof createRumorSchema>;

// Gossip - synthesized narratives from rumors
export const gossipSchema = z.object({
  id: z.string().uuid(),
  questionId: z.string().uuid().nullish(),
  text: z.string(),
  title: z.string().max(255),
  slug: z.string().max(255),
  type: z.string().nullish(),
  abstract: z.string().nullish(),
  summary: z.string().nullish(),
  contents: z.string().nullish(),
  tags: tagsSchema,
  rumorIds: z.array(z.string().uuid()),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const gossipWithRumorsSchema = gossipSchema.omit({ rumorIds: true }).extend({
  rumors: z.array(rumorSchema),
});

export const createGossipSchema = gossipSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type Gossip = z.infer<typeof gossipSchema>;
export type GossipWithRumors = z.infer<typeof gossipWithRumorsSchema>;
export type CreateGossip = z.infer<typeof createGossipSchema>;

export const analysisTypeSchema = z.enum([
  "intelligence-briefing",
  "informational",
  "risk",
  "concern",
  "opportunity",
  "action",
]);

// Analysis - structured investigation of gossip using data
export const analysisSchema = z.object({
  id: z.string().uuid(),
  gossipId: z.string().uuid(),
  agentSlug: z.string(), // Will be used to loosely map to an externally facing "agent"
  hypothesis: z.string(),
  type: analysisTypeSchema,
  title: z.string(),
  method: z.object({
    sql: z.string().nullish(), // SQL query to test hypothesis
    description: z.string(), // Description of methodology
    rumorIds: z.array(z.string().uuid()).nullish(), // IDs of rumors that were used to generate the result
  }),
  results: z
    .object({
      resultId: z.string().nullish(), // ID reference to stored results
      summary: z.string().nullish(), // Summary of results
    })
    .nullish(),
  conclusion: z
    .object({
      text: z.string().nullish(), // LLM-generated or human analysis of results
      validated: z.boolean().nullish(), // Whether hypothesis was validated
    })
    .nullish(),
  tags: tagsSchema,
  status: z.enum(["draft", "in_progress", "completed", "failed"]),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const createAnalysisSchema = analysisSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});
export const updateAnalysisSchema = analysisSchema.omit({
  id: true,
  gossipId: true,
  agentSlug: true,
  createdAt: true,
  updatedAt: true,
});

export type Analysis = z.infer<typeof analysisSchema>;
export type AnalysisType = z.infer<typeof analysisTypeSchema>;
export type CreateAnalysis = z.infer<typeof createAnalysisSchema>;
export type UpdateAnalysis = z.infer<typeof updateAnalysisSchema>;

// These are identical for now, but don't necessarily need to be
export const insightTypeSchema = analysisTypeSchema;

// Insight - versioned narrative combining gossip and analyses
export const insightSchema = z.object({
  id: z.string().uuid(),
  version: z.number(), // Version number
  title: z.string(),
  slug: z.string().max(255),
  text: z.string(), // The narrative/insight content
  type: insightTypeSchema,
  sources: z.object({
    gossipId: z.string().uuid(), // The original gossip
    analysisIds: z.array(z.string().uuid()), // All analyses considered
  }),
  tags: tagsSchema,
  status: z.enum(["draft", "published", "archived"]),
  updateReason: z.string().nullish(), // Explanation of what prompted this version
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const insightWithSourcesSchema = insightSchema.extend({
  sources: z.object({
    gossip: gossipSchema.nullish(),
    analyses: z.array(analysisSchema).nullish(),
  }),
});

export const analysisMethodWithRumorsSchema = z.object({
  sql: z.string().nullish(),
  description: z.string(),
  rumors: z.array(rumorSchema).nullish(),
});

export const createInsightSchema = insightSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});
export const updateInsightSchema = insightSchema.partial();
export const analysisWithRumorsSchema = analysisSchema.extend({
  method: analysisMethodWithRumorsSchema,
});

export const insightWithSourcesAndRumorsSchema = insightSchema.extend({
  sources: z.object({
    gossip: gossipSchema.nullish(),
    analyses: z.array(analysisWithRumorsSchema).nullish(),
  }),
});

export type Insight = z.infer<typeof insightSchema>;
export type InsightType = z.infer<typeof insightTypeSchema>;
export type CreateInsight = z.infer<typeof createInsightSchema>;
export type InsightWithSources = z.infer<typeof insightWithSourcesSchema>;
export type UpdateInsight = z.infer<typeof updateInsightSchema>;
export type AnalysisWithRumors = z.infer<typeof analysisWithRumorsSchema>;
export type InsightWithSourcesAndRumors = z.infer<typeof insightWithSourcesAndRumorsSchema>;

// Insight V2 //////////////////////////////////////////////////////////////

export const trendThoughtsTypesSchema = z
  .enum(["TRENDING_INSIGHT", "SUPPORTING_DATA", "BROADER_CONTEXT", "WHY_IT_MATTERS", "WHAT_TO_WATCH"])
  .describe(`
  TRENDING_INSIGHT: Clear statement on most significant current trend
  SUPPORTING_DATA: Key metrics showing magnitude and timeline
  BROADER_CONTEXT: How this fits into the ecosystem
  WHY_IT_MATTERS: Implications for users or protocols
  WHAT_TO_WATCH: Specific metrics to monitor going forward
`);

export const trendThoughtsSchema = z.object({
  thoughts: z.array(
    z.object({
      type: trendThoughtsTypesSchema,
      text: z.string(),
    }),
  ),
});

export const opportunityThoughtsTypesSchema = z
  .enum(["OPPORTUNITY_SIGNAL", "EVIDENCE", "RISK_FACTORS", "COMPARABLE_EVENTS", "ACTIVITY_SUGGESTION"])
  .describe(`
  OPPORTUNITY_SIGNAL: Statement highlighting potential opportunity
  EVIDENCE: Key metrics supporting this assessment
  RISK_FACTORS: Balanced view with potential concerns
  COMPARABLE_EVENTS: Similar past situations and outcomes
  ACTIVITY_SUGGESTION: Subtle prompt toward beneficial ecosystem activity
`);

export const opportunityThoughtsSchema = z.object({
  thoughts: z.array(
    z.object({
      type: opportunityThoughtsTypesSchema,
      text: z.string(),
    }),
  ),
});

export const emergingSignalThoughtsTypesSchema = z
  .enum(["EMERGING_SIGNAL", "HISTORICAL_CONTEXT", "CONFLICTING_SIGNALS", "EXPERT_PERSPECTIVE", "PREPARATION_POINT"])
  .describe(`
  EMERGING_SIGNAL: Statement on developing trend
  HISTORICAL_CONTEXT: How this pattern has played out before
  CONFLICTING_SIGNALS: Balanced view of contradictory indicators
  EXPERT_PERSPECTIVE: What sophisticated analysis suggests
  PREPARATION_POINT: How users might position themselves
`);

export const emergingSignalThoughtsSchema = z.object({
  thoughts: z.array(
    z.object({
      type: emergingSignalThoughtsTypesSchema,
      text: z.string(),
    }),
  ),
});

export const generatedChainOfThoughtTypeSchema = trendThoughtsTypesSchema
  .or(opportunityThoughtsTypesSchema)
  .or(emergingSignalThoughtsTypesSchema);

export const generatedChainOfThoughtSchema = z.object({
  thoughts: z.array(
    z.object({
      type: generatedChainOfThoughtTypeSchema,
      text: z.string(),
    }),
  ),
});

export type GeneratedChainOfThought = z.infer<typeof generatedChainOfThoughtSchema>;

export const thoughtSchema = z.object({
  id: z.string().uuid(),
  type: generatedChainOfThoughtTypeSchema,
  text: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  gossipId: z.string().uuid(),
});

export const createThoughtSchema = thoughtSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type Thought = z.infer<typeof thoughtSchema>;
export type ThoughtType = z.infer<typeof generatedChainOfThoughtTypeSchema>;
export type CreateThought = z.infer<typeof createThoughtSchema>;

export const insightV2TypeSchema = z.enum(["emerging-signal", "trend", "opportunity"]);
export const insightV2SentimentSchema = z.enum(["positive", "negative", "neutral"]);

export const insightV2Schema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  slug: z.string().max(255),
  abstract: z.string().describe("Card text, ~2 sentences"),
  summary: z.string().describe("Main Content, ~2 paragraphs"),
  blockchains: z.array(z.string()).describe("Blockchain names"),
  questionId: z.string().uuid().describe("UUID of the un-enriched question that was used to generate this insight"),
  chainOfThought: z.object({
    question: z.string().describe("Enriched Question"),
    answers: z
      .record(generatedChainOfThoughtTypeSchema, z.string())
      .describe("Answers to the enriched question; eg: {trendingInsight: '...', broaderContext: '...'}"),
  }),
  tags: tagsSchema,
  type: insightV2TypeSchema,
  sentiment: insightV2SentimentSchema,
  sources: z.object({
    rumorIds: z.array(z.string().uuid()),
  }),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const createInsightV2Schema = insightV2Schema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type InsightV2 = z.infer<typeof insightV2Schema>;
export type InsightV2Type = z.infer<typeof insightV2TypeSchema>;
export type CreateInsightV2 = z.infer<typeof createInsightV2Schema>;

export const generatedAnalysisSchema = z.object({
  text: z.string(),
  rumorIds: z.array(z.string()),
});

export type GeneratedAnalysis = z.infer<typeof generatedAnalysisSchema>;

export const generatedInsightSchema = z.object({
  summary: z.string().describe("Main Content, ~2 paragraphs"),
  abstract: z.string().describe("Card text, ~2 sentences"),
  title: z.string(),
  slug: z.string(),
  blockchains: z.array(z.string()).describe("Any blockchains or cryptocurrencies mentioned in the insight."),
  tags: tagsSchema.describe("Any tags that are applicable to the insight."),
  sentiment: insightV2SentimentSchema,
});

export type GeneratedInsight = z.infer<typeof generatedInsightSchema>;
