import { z } from "zod";
import type { Query } from "../query";

export const paramValuesSchema = z.object({
  values: z.record(z.string()),
  runs: z.number().optional(),
});

export const ephemeralDashboardParamsSchema = z.record(paramValuesSchema);

export type DashboardEphemeralParamData = z.infer<typeof ephemeralDashboardParamsSchema>;

export type ParamValues = z.infer<typeof paramValuesSchema>;

export const mergeQueryParameters = (queries: Query[]): Query["parameters"] => {
  const parameterMap = new Map<string, Query["parameters"][number]>();

  queries.forEach((query) => {
    query.parameters?.forEach((param) => {
      if (parameterMap.has(param.name)) {
        // Merge the parameter values if the name already exists
        const existingParam = parameterMap.get(param.name)!;
        const mergedValue = param.value ?? existingParam.value;
        parameterMap.set(param.name, { ...param, value: mergedValue });
      } else {
        // Add the parameter if it doesn't exist
        parameterMap.set(param.name, param);
      }
    });
  });

  return Array.from(parameterMap.values());
};

export const updateEphemeralParamValues = (
  paramData: DashboardEphemeralParamData,
  paramCellId: string,
  paramName: string,
  value: string,
) => {
  const updatedParamData = {
    ...paramData,
    [paramCellId]: {
      ...paramData[paramCellId],
      values: {
        ...paramData[paramCellId]?.values,
        [paramName]: value,
      },
    },
  };
  return updatedParamData;
};

export const updateEphemeralParamRuns = (paramData: DashboardEphemeralParamData, paramCellId: string) => {
  const updatedParamData = {
    ...paramData,
    [paramCellId]: {
      ...paramData[paramCellId],
      runs: (paramData[paramCellId]?.runs || 0) + 1,
    },
  };
  return updatedParamData;
};

// Function to order parameters based on paramIdOrder
export const reorderParameters = (parameters: Query["parameters"], paramIdOrder: string[] | undefined) => {
  if (!paramIdOrder) return parameters;

  const paramMap = new Map(parameters.map((param) => [param.id, param]));
  const orderedParams = paramIdOrder.map((paramId) => paramMap.get(paramId)).filter((param) => param !== undefined);
  const unorderedParams = parameters.filter((param) => !paramIdOrder.includes(param.id));

  return [...orderedParams, ...unorderedParams].filter(Boolean) as Query["parameters"];
};

// Utility function to toggle hidden parameters
export const toggleHiddenParams = (paramId: string, hiddenParams: string[] | undefined): string[] => {
  const currentHiddenParams = hiddenParams ?? [];
  return currentHiddenParams.includes(paramId)
    ? currentHiddenParams.filter((id) => id !== paramId)
    : [...currentHiddenParams, paramId];
};
