import { z } from "zod";

export const dataDescriptionSchema = z.object({
  id: z.string(),
  databaseName: z.string(),
  schemaName: z.string(),
  tableName: z.string(),
  clusteringKey: z.string(),
  tableComment: z.string(),
  columnDefinitions: z.string(),
  generatedTextDescription: z.string(),
  lastUpdated: z.date(),
});

export type DataDescription = z.infer<typeof dataDescriptionSchema>;

export const dataDescriptionNamespace = "snowflake-table-metadata";
