/**
 * Truncates a wallet address by showing the first 6 and last 6 characters with ellipsis in between.
 * If maxLength is provided and the address is shorter or equal to maxLength, returns the full address.
 * @param address - The Ethereum address to truncate
 * @param options - Optional configuration object
 * @param options.maxLength - Optional maximum length before truncation is applied
 * @returns The truncated address string (e.g. "0x1234...5678") or full address if shorter than maxLength
 */
export const truncateAddress = (address: string, options?: { maxLength?: number }) => {
  if (options?.maxLength && address.length <= options.maxLength) {
    return address;
  }

  return `${address.slice(0, 6)}...${address.slice(6 * -1)}`;
};
