import { z } from "zod";
import { type Query, type QueryParameterEphemeralExecution, queryParamEphemeralExecutionSchema } from "../query";

export const layoutParamsSchema = z.object({
  cellId: z.string(),
  parameters: z.record(queryParamEphemeralExecutionSchema),
  latestRunHash: z.string().optional(),
});

export const dashboardParamsSchema = z.record(layoutParamsSchema);

export type DashboardParams = z.infer<typeof dashboardParamsSchema>;

export type LayoutParams = z.infer<typeof layoutParamsSchema>;

// Merge parameters with layout data
export const mergeParameters = (queries: Query[], layoutData: LayoutParams): Query["parameters"] => {
  const parameters = queries.flatMap((query) => query.parameters || []);

  return parameters.map((param) => {
    const paramName = param.name;
    if (layoutData?.parameters && paramName in layoutData.parameters) {
      const layoutParam = layoutData.parameters[paramName];
      if (layoutParam) {
        return {
          ...param,
          value: layoutParam.value,
        };
      }
    }
    return param;
  });
};

export const updateParamData = (
  dashboardParams: DashboardParams,
  paramsCellId: string,
  paramName: string,
  value: string,
  type: QueryParameterEphemeralExecution["type"],
): DashboardParams => {
  const existingData = dashboardParams[paramsCellId] || {
    cellId: "",
    parameters: {},
  };

  const updatedParameters = {
    ...existingData.parameters,
    [paramName]: {
      // Ensure all required fields are present
      ...(existingData.parameters?.[paramName] ?? { name: paramName }),
      value,
      type,
    },
  };

  return {
    ...dashboardParams,
    [paramsCellId]: {
      cellId: existingData.cellId || "defaultCellId", // Provide a default value for cellId if undefined
      parameters: updatedParameters,
    },
  };
};

// Function to order parameters based on paramIdOrder
export const orderParameters = (parameters: Query["parameters"], paramIdOrder: string[] | undefined) => {
  if (!paramIdOrder) return parameters;

  const paramMap = new Map(parameters.map((param) => [param.id, param]));
  const orderedParams = paramIdOrder.map((paramId) => paramMap.get(paramId)).filter((param) => param !== undefined);
  const unorderedParams = parameters.filter((param) => !paramIdOrder.includes(param.id));

  return [...orderedParams, ...unorderedParams].filter(Boolean) as Query["parameters"];
};

// Utility function to toggle hidden parameters
export const toggleHiddenParam = (paramId: string, hiddenParams: string[] | undefined): string[] => {
  const currentHiddenParams = hiddenParams ?? [];
  return currentHiddenParams.includes(paramId)
    ? currentHiddenParams.filter((id) => id !== paramId)
    : [...currentHiddenParams, paramId];
};
