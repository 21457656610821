import { z } from "zod";

const type = z.union([z.literal("query"), z.literal("dashboard")]);
export const themeSchema = z.union([z.literal("light"), z.literal("dark")]).optional();

export const explorerTabSchema = z.object({
  typeId: z.string(),
  type,
  active: z.boolean().default(false),
});

export const explorerTabsSchema = z.array(explorerTabSchema);
export const profileExplorerTabSchema = z.record(z.string(), z.array(explorerTabSchema));
// instead of an array, explorer tabs are now keyed by the profileId, so we need to make sure we can handle both
const tabTypeUnion = z.union([z.array(explorerTabSchema), profileExplorerTabSchema]);

export const sidebarTabSchema = z.union([
  z.literal("recents"),
  z.literal("my-work"),
  z.literal("schema"),
  z.literal("live-query"),
  z.literal("widgets"),
  z.literal("layers"),
]);

export const schema = z.object({
  explorerTabs: tabTypeUnion.default({}),
  theme: themeSchema,
  isRecentOpen: z.boolean().default(true),
  isSidebarOpen: z.boolean().default(true),
  defaultLiveQueryProvider: z.string().nullish(),
  sidebarTab: sidebarTabSchema.nullish().default("recents"),
});

export const updateSchema = z
  .object({
    theme: themeSchema,
    isRecentOpen: z.boolean(),
    isSidebarOpen: z.boolean(),
    sidebarTab: sidebarTabSchema,
  })
  .partial();

export type UserState = z.infer<typeof schema>;
export type UserStateUpdate = z.infer<typeof updateSchema>;
export type UserStateTheme = z.infer<typeof themeSchema>;
export type UserStateExplorerTab = z.infer<typeof explorerTabSchema>;
export type UserStateExplorerTabType = z.infer<typeof type>;
export type UserStateSidebarTab = z.infer<typeof sidebarTabSchema>;
