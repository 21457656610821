import { z } from "zod";

export interface Provider {
  provider: LiveQueryNodeProvider;
  chain: string;
  network: string;
}

export interface ChainNetwork {
  chain: string;
  network: string;
  iconFileName: string;
  providers: Provider[];
}

export const liveQueryNodeProviderSchema = z.union([
  z.literal("alchemy"),
  z.literal("quicknode"),
  z.literal("validationcloud"),
  z.literal("chainstack"),
]);

export type LiveQueryNodeProvider = z.infer<typeof liveQueryNodeProviderSchema>;

// returns a canonical chain/network for a given provider's chain/network
export function getCanonicalChainNetworkByProvider(
  provider: string,
  chain: string,
  network: string,
): ChainNetwork | undefined {
  return providerMap.find((chainNetwork: ChainNetwork) => {
    return chainNetwork.providers.some((providerObj: Provider) => {
      return providerObj.provider === provider && providerObj.chain === chain && providerObj.network === network;
    });
  });
}

// returns a list of canonical chain/networks for a given provider
export function getAllCanonicalChainNetworksByProvider(provider: LiveQueryNodeProvider): ChainNetwork[] {
  return providerMap.filter((chainNetwork: ChainNetwork) => {
    return chainNetwork.providers.some((providerObj: Provider) => {
      return providerObj.provider === provider;
    });
  });
}

// returns a list of canonical chain/networks
export function getCanonicalSchemaList(): string[] {
  return providerMap.map((chainNetwork: ChainNetwork) => {
    return `${chainNetwork.chain}_${chainNetwork.network}`;
  });
}

// returns a provider's chain/network for a given canonical chain/network
export function getProviderByCanonicalChainNetwork(
  provider: LiveQueryNodeProvider,
  chain: string,
  network: string,
): Provider | undefined {
  for (const chainNetwork of providerMap) {
    if (chainNetwork.chain === chain && chainNetwork.network === network) {
      // iterate over the providers array
      for (const providerObj of chainNetwork.providers) {
        // if the current provider object matches the provided provider
        if (providerObj.provider === provider) {
          return providerObj;
        }
      }
    }
  }
}

// returns a provider's chain/networks
export function getProviderItemsByProvider(provider: LiveQueryNodeProvider): Provider[] {
  const items: Provider[] = [];
  for (const chainNetwork of providerMap) {
    for (const providerObj of chainNetwork.providers) {
      // if the current provider object matches the provided provider
      if (providerObj.provider === provider) {
        items.push(providerObj);
      }
    }
  }
  return items;
}

// returns node provider's secret keys - ie eth-mainnet
export function getNodeProviderSecretKeys(provider: LiveQueryNodeProvider): string[] {
  return getProviderItemsByProvider(provider).map((provider) => {
    return `${provider.chain}-${provider.network}`;
  });
}

export function getProviderNamesByCanonicalSchemaName(name: string): string[] {
  let providerNames: string[] = [];
  providerMap.forEach((item) => {
    if (`${item.chain}_${item.network}` === name) {
      providerNames = item.providers.map((p) => p.provider);
    }
  });
  return providerNames;
}

export function getByCanonicalSchemaName(name: string): ChainNetwork | undefined {
  return providerMap.find((item) => {
    return `${item.chain}_${item.network}` === name;
  });
}

export function getProvidersByCanonicalSchemaName(schemaName: string): Provider[] {
  let providers: Provider[] = [];
  providerMap.forEach((item) => {
    if (`${item.chain}_${item.network}` === schemaName) {
      providers = item.providers;
    }
  });
  return providers;
}

export const providerMap: ChainNetwork[] = [
  {
    chain: "algorand",
    network: "mainnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "algorand",
        network: "algorand-mainnet",
      },
    ],
  },
  {
    chain: "algorand",
    network: "testnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "algorand",
        network: "algorand-testnet",
      },
    ],
  },
  {
    chain: "algorand",
    network: "betanet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "algorand",
        network: "algorand-betanet",
      },
    ],
  },
  {
    chain: "aptos",
    network: "mainnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "aptos",
        network: "aptos-mainnet",
      },
    ],
  },
  {
    chain: "aptos",
    network: "testnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "aptos",
        network: "aptos-testnet",
      },
    ],
  },
  {
    chain: "arbitrum_nova",
    network: "mainnet",
    iconFileName: "arbitrum.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "arbitrum-nova",
        network: "nova-mainnet",
      },
    ],
  },
  {
    chain: "arbitrum_one",
    network: "mainnet",
    iconFileName: "arbitrum.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "arbitrum",
        network: "arbitrum-mainnet",
      },
      {
        provider: "alchemy",
        chain: "arb",
        network: "mainnet",
      },
      {
        provider: "chainstack",
        chain: "arbitrum_one",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "arbitrum_one",
    network: "goerli",
    iconFileName: "arbitrum.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "arbitrum",
        network: "arbitrum-goerli",
      },
      {
        provider: "alchemy",
        chain: "arb",
        network: "goerli",
      },
      {
        provider: "chainstack",
        chain: "arbitrum_one",
        network: "goerli",
      },
    ],
  },
  {
    chain: "avalanche_c",
    network: "mainnet",
    iconFileName: "avalanche.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "avalanche",
        network: "avalanche-mainnet",
      },
      {
        provider: "validationcloud",
        chain: "avalanche_c",
        network: "mainnet",
      },
      {
        provider: "chainstack",
        chain: "avalanche_c",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "avalanche_c",
    network: "testnet",
    iconFileName: "avalanche.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "avalanche",
        network: "avalanche-testnet",
      },
      {
        provider: "validationcloud",
        chain: "avalanche_c",
        network: "testnet",
      },
      {
        provider: "chainstack",
        chain: "avalanche_c",
        network: "testnet",
      },
    ],
  },
  {
    chain: "base",
    network: "goerli",
    iconFileName: "base.png",
    providers: [
      {
        provider: "quicknode",
        chain: "base",
        network: "base-goerli",
      },
      {
        provider: "chainstack",
        chain: "base",
        network: "goerli",
      },
    ],
  },
  {
    chain: "base",
    network: "mainnet",
    iconFileName: "base.png",
    providers: [
      {
        provider: "quicknode",
        chain: "base",
        network: "base-mainnet",
      },
      {
        provider: "chainstack",
        chain: "base",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "bitcoin",
    network: "mainnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "bitcoin",
        network: "btc",
      },
    ],
  },
  {
    chain: "bitcoin",
    network: "testnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "bitcoin",
        network: "btc-testnet",
      },
    ],
  },
  {
    chain: "bsc",
    network: "mainnet",
    iconFileName: "bsc.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "bnb-smart-chain",
        network: "bsc",
      },
      {
        provider: "chainstack",
        chain: "bsc",
        network: "mainnet",
      },
      {
        provider: "validationcloud",
        chain: "bsc",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "bsc",
    network: "testnet",
    iconFileName: "bsc.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "bnb-smart-chain",
        network: "bsc-testnet",
      },
      {
        provider: "chainstack",
        chain: "bsc",
        network: "testnet",
      },
    ],
  },
  {
    chain: "celo",
    network: "mainnet",
    iconFileName: "celo.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "celo",
        network: "celo-mainnet",
      },
    ],
  },
  {
    chain: "ethereum",
    network: "mainnet",
    iconFileName: "ethereum.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "ethereum",
        network: "mainnet",
      },
      {
        provider: "alchemy",
        chain: "eth",
        network: "mainnet",
      },
      {
        provider: "chainstack",
        chain: "ethereum",
        network: "mainnet",
      },
      {
        provider: "validationcloud",
        chain: "ethereum",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "ethereum",
    network: "goerli",
    iconFileName: "ethereum.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "ethereum",
        network: "ethereum-goerli",
      },
      {
        provider: "alchemy",
        chain: "eth",
        network: "goerli",
      },
      {
        provider: "chainstack",
        chain: "ethereum",
        network: "goerli",
      },
      {
        provider: "validationcloud",
        chain: "ethereum",
        network: "goerli",
      },
    ],
  },
  {
    chain: "ethereum",
    network: "sepolia",
    iconFileName: "ethereum.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "ethereum",
        network: "ethereum-sepolia",
      },
      {
        provider: "alchemy",
        chain: "eth",
        network: "sepolia",
      },
      {
        provider: "chainstack",
        chain: "ethereum",
        network: "sepolia",
      },
    ],
  },
  {
    chain: "fantom",
    network: "mainnet",
    iconFileName: "fantom.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "fantom",
        network: "fantom",
      },
      {
        provider: "chainstack",
        chain: "fantom",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "gnosis",
    network: "mainnet",
    iconFileName: "gnosis.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "gnosis",
        network: "xdai",
      },
      {
        provider: "chainstack",
        chain: "gnosis",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "harmony",
    network: "mainnet",
    iconFileName: "harmony.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "harmony",
        network: "harmony-mainnet",
      },
    ],
  },
  {
    chain: "harmony",
    network: "testnet",
    iconFileName: "harmony.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "harmony",
        network: "harmony-testnet",
      },
    ],
  },
  {
    chain: "near",
    network: "mainnet",
    iconFileName: "near.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "near",
        network: "near-mainnet",
      },
    ],
  },
  {
    chain: "near",
    network: "testnet",
    iconFileName: "near.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "near",
        network: "near-testnet",
      },
    ],
  },
  {
    chain: "optimism",
    network: "mainnet",
    iconFileName: "optimism.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "optimistic-ethereum",
        network: "optimism",
      },
      {
        provider: "alchemy",
        chain: "opt",
        network: "mainnet",
      },
      {
        provider: "chainstack",
        chain: "optimism",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "optimism",
    network: "goerli",
    iconFileName: "optimism.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "optimistic-ethereum",
        network: "optimism-goerli",
      },
      {
        provider: "alchemy",
        chain: "opt",
        network: "goerli",
      },
      {
        provider: "chainstack",
        chain: "optimism",
        network: "goerli",
      },
    ],
  },
  {
    chain: "optimism",
    network: "kovan",
    iconFileName: "optimism.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "optimistic-ethereum",
        network: "optimism-kovan",
      },
    ],
  },
  {
    chain: "palm",
    network: "mainnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "palm",
        network: "palm-mainnet",
      },
    ],
  },
  {
    chain: "palm",
    network: "testnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "palm",
        network: "palm-testnet",
      },
    ],
  },
  {
    chain: "polygon",
    network: "mainnet",
    iconFileName: "polygon.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "polygon",
        network: "matic",
      },
      {
        provider: "alchemy",
        chain: "polygon",
        network: "mainnet",
      },
      {
        provider: "chainstack",
        chain: "polygon",
        network: "mainnet",
      },
      {
        provider: "chainstack",
        chain: "polygon",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "polygon",
    network: "testnet",
    iconFileName: "polygon.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "polygon",
        network: "matic-testnet",
      },
      {
        provider: "alchemy",
        chain: "polygon",
        network: "mumbai",
      },
      {
        provider: "chainstack",
        chain: "polygon",
        network: "mumbai",
      },
      {
        provider: "chainstack",
        chain: "polygon",
        network: "testnet",
      },
    ],
  },
  {
    chain: "polygon_zkevm",
    network: "mainnet",
    iconFileName: "polygon.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "polygon-zkevm",
        network: "zkevm-mainnet",
      },
      {
        provider: "alchemy",
        chain: "polygonzkevm",
        network: "mainnet",
      },
      {
        provider: "chainstack",
        chain: "polygon_zkevm",
        network: "mainnet",
      },
      {
        provider: "validationcloud",
        chain: "polygon_zkevm",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "polygon_zkevm",
    network: "testnet",
    iconFileName: "polygon.svg",
    providers: [
      {
        provider: "quicknode",
        chain: "polygon-zkevm",
        network: "zkevm-testnet",
      },
      {
        provider: "alchemy",
        chain: "polygonzkevm",
        network: "testnet",
      },
      {
        provider: "chainstack",
        chain: "polygon_zkevm",
        network: "mainnet",
      },
      {
        provider: "validationcloud",
        chain: "polygon_zkevm",
        network: "mainnet",
      },
    ],
  },
  {
    chain: "solana",
    network: "mainnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "solana",
        network: "solana-mainnet",
      },
    ],
  },
  {
    chain: "solana",
    network: "testnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "solana",
        network: "solana-testnet",
      },
    ],
  },
  {
    chain: "solana",
    network: "devnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "solana",
        network: "solana-devnet",
      },
    ],
  },
  {
    chain: "stacks",
    network: "mainnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "stacks",
        network: "stacks-mainnet",
      },
    ],
  },
  {
    chain: "stacks",
    network: "testnet",
    iconFileName: "",
    providers: [
      {
        provider: "quicknode",
        chain: "stacks",
        network: "stacks-testnet",
      },
    ],
  },
];
