import { z } from "zod";

export const schema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  slug: z.string(),
  parentCollectionId: z.string().uuid().nullable(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  createdAt: z.date().default(new Date()),
  updatedAt: z.date().default(new Date()),
  profileId: z.string().uuid(),
  domainType: z.literal("Collection").default("Collection"),
});

export const newSchema = schema.pick({
  name: true,
  parentCollectionId: true,
});

export const updateSchema = newSchema
  .omit({
    parentCollectionId: true,
  })
  .partial();

export type Collection = z.infer<typeof schema>;
export type CollectionNew = z.infer<typeof newSchema>;
export type CollectionUpdate = z.infer<typeof updateSchema>;

export type MultiMoveItem = {
  // type: "dashboard" | "query" | "collection";
  id: string;
  typename: "dashboard" | "query" | "collection" | "visualization" | "table";
};

export interface CollectionRepository {
  find: (id: string) => Promise<Collection | null>;
  findMany: (ids: string[]) => Promise<Collection[]>;
  create: (collection: CollectionNew, userId: string, profileId: string) => Promise<Collection>;
  update: (id: string, collection: CollectionUpdate, userId: string) => Promise<Collection>;
  delete: (id: string) => Promise<void>;
  move: (id: string, userId: string, collectionId: string | null) => Promise<Collection>;
}
