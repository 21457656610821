import { z } from "zod";
import type { Identity } from "../utils/identity";
import type { Decimal } from "../utils/zod";
import { type ZodSchema, decimalSchema } from "../utils/zod";

export type DealDelegation = {
  id: string;
  startDate: Date;
  endDate: Date | null;
  maxMonths: number | null;
  note: string | null;
  tokenAmountSignDate: Decimal;
  tokenAmountCurrent: Decimal;
  tokenPriceSignDate: Decimal;
  tokenPriceCurrent: Decimal;
  totalEstValueUsdCurrent: Decimal;
  totalEstValueUsdSignDate: Decimal;
  tokenId: string;
  aprSignDate: Decimal;
  aprCurrent: Decimal;
  isAprLocked: boolean;
  isTokenPriceLocked: boolean;
  validatorCommissionSignDate: Decimal;
  validatorCommissionCurrent: Decimal;
  createdById: string;
  partnerDealId: string;
  active: boolean;
};

export type DealDelegationNew = Identity<
  Omit<
    DealDelegation,
    | "id"
    | "startDate"
    | "aprCurrent"
    | "aprSignDate"
    | "validatorCommissionCurrent"
    | "tokenAmountCurrent"
    | "tokenPriceCurrent"
    | "tokenPriceSignDate"
    | "maxMonths"
    | "isAprLocked"
    | "isTokenPriceLocked"
    | "endDate"
    | "active"
    | "totalEstValueUsdCurrent"
    | "totalEstValueUsdSignDate"
    | "note"
  > & {
    startDate?: Date;
    aprCurrent?: Decimal;
    aprSignDate?: Decimal;
    validatorCommissionCurrent?: Decimal;
    tokenAmountCurrent?: Decimal;
    tokenPriceCurrent?: Decimal;
    tokenPriceSignDate?: Decimal;
    maxMonths?: number;
    isAprLocked?: boolean;
    isTokenPriceLocked?: boolean;
    endDate?: Date;
    active?: boolean;
    totalEstValueUsdCurrent?: Decimal;
    totalEstValueUsdSignDate?: Decimal;
    note?: string | null;
  }
>;
export type DealDelegationUpdate = Identity<Partial<Omit<DealDelegation, "id" | "partnerDealId">> & { id: string }>;

export const dealDelegationSchema = z.object({
  id: z.string().uuid(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date().nullable().default(null),
  maxMonths: z.coerce.number().int().positive().nullable().default(null),
  tokenAmountSignDate: decimalSchema,
  tokenAmountCurrent: decimalSchema,
  tokenPriceSignDate: decimalSchema,
  tokenPriceCurrent: decimalSchema,
  totalEstValueUsdSignDate: decimalSchema,
  totalEstValueUsdCurrent: decimalSchema,
  tokenId: z.string().uuid(),
  aprSignDate: decimalSchema,
  aprCurrent: decimalSchema,
  isAprLocked: z.coerce.boolean(),
  isTokenPriceLocked: z.coerce.boolean(),
  validatorCommissionSignDate: decimalSchema,
  validatorCommissionCurrent: decimalSchema,
  createdById: z.string().uuid(),
  partnerDealId: z.string().uuid(),
  active: z.coerce.boolean().default(true),
  note: z.string().nullable(),
}) satisfies ZodSchema<DealDelegation>;

export const dealDelegationCreateSchema = dealDelegationSchema
  .omit({
    id: true,
    note: true,
  })
  .extend({
    aprCurrent: decimalSchema.optional(),
    // apr can be potentially provided by StakingRewards on creation
    aprSignDate: decimalSchema.optional(),
    validatorCommissionCurrent: decimalSchema.optional(),
    tokenAmountCurrent: decimalSchema.optional(),
    tokenPriceCurrent: decimalSchema.optional(),
    // token price can potentially be provided by CoinGecko on creation
    tokenPriceSignDate: decimalSchema.optional(),
    totalEstValueUsdCurrent: decimalSchema.optional(),
    totalEstValueUsdSignDate: decimalSchema.optional(),
    maxMonths: z.number().int().positive().optional(),
    isAprLocked: z.boolean().optional(),
    isTokenPriceLocked: z.boolean().optional(),
    endDate: z.coerce.date().optional(),
    note: z.string().nullable().optional(),
  }) satisfies ZodSchema<DealDelegationNew>;

export const dealDelegationUpdateSchema = dealDelegationSchema
  .partial()
  .extend({
    id: z.string().uuid(),
  })
  .omit({
    partnerDealId: true,
  }) satisfies ZodSchema<DealDelegationUpdate>;
