import { z } from "zod";
import type { Token } from "../token";
import type { ZodSchema } from "../utils/zod";

/**
 * A tag type indicates the origin of the tag. flipside-tag and project are both auto generated tags.
 */
export type TagType = "flipside-tag" | "project" | "user-generated" | `${string}`;

/**
 * A tag resource indicated the domain type that a tag can be applied to.
 */
export type TagResourceType = "dashboard" | "query" | "visualization" | "table";

export interface Tag {
  id: string;
  name: string;
  displayName: string | null;
  featured: boolean;
  iconFileName: string | null;
  iconSrc?: string | null;
  sortOrder: number | null;
  type: TagType;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProjectWithToken extends Tag {
  token: Token;
}

export interface TopTag {
  id: string;
  name: string;
  resourceTagCount: number;
}

export type NewTag = Pick<Tag, "name">;
export type AssignTag = Pick<ResourceTag, "tagId" | "resourceId" | "resourceType">;

/**
 * A user resource tag is a tag that the user has explicitly added to this resource.
 * This helps us identify which tags to show (auto generated or user created)
 */
export interface ResourceTag {
  id: string;
  tagId: string;
  resourceId: string;
  resourceType: TagResourceType;
  createdAt: Date;
  updatedAt: Date;
  createdById: string;
  updatedById: string;
}

export const redisPrefix = "snowflake:tags";

// SCHEMAS ////////////////////////////////////////////////////////////////////

export const tagTypes = z.enum(["flipside-tag", "project", "user-generated"]) satisfies ZodSchema<TagType>;

export const schema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  displayName: z.string().nullable(),
  featured: z.boolean().default(false),
  iconFileName: z.string().nullable(),
  iconSrc: z.string().nullish(),
  sortOrder: z.number().nullable(),
  type: z.string(),
  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
}) satisfies ZodSchema<Tag>;

export const newTagSchema = z.object({
  name: z.string().min(1).max(50),
}) satisfies ZodSchema<NewTag>;

export const tagResourceTypeSchema = z.enum([
  "dashboard",
  "query",
  "visualization",
  "table",
]) satisfies ZodSchema<TagResourceType>;

export const assignTagSchema = z.object({
  tagId: z.string().uuid(),
  resourceId: z.string().uuid(),
  resourceType: tagResourceTypeSchema,
}) satisfies ZodSchema<AssignTag>;

export const resourceTagSchema = z.object({
  id: z.string().uuid(),
  tagId: z.string().uuid(),
  resourceId: z.string().uuid(),
  resourceType: tagResourceTypeSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
}) satisfies ZodSchema<ResourceTag>;

// UTILS //////////////////////////////////////////////////////////////////////

export function isUserGeneratedTag(tag: Tag): boolean {
  return tag.type === "user-generated";
}

export function isProjectTag(tag: Tag): boolean {
  return tag.type === "project";
}

export function isFlipsideTag(tag: Tag): boolean {
  return tag.type === "flipside-tag";
}

export function normalizeTagName(name: string): string {
  return name.toLowerCase().replaceAll(" ", "-");
}

export function displayNameFromNormalizedName(name: string): string {
  return name.replaceAll("-", " ").replace(/^\w/, (c) => c.toUpperCase());
}
