import { z } from "zod";
import { paymentRecordSchema, userRewardsSchema } from "../rewards";
import {
  adhocManifestItem,
  adhocPaymentAction,
  claimableManualPaymentSchema,
  createAdhocPaymentSchema,
  manualPaymentItemDB,
} from "./adhoc";

// when creating adhoc payments, Treasury will return a this format for each unsuccessful line item creation
export const failedAdHocCreateSchema = z.object({
  reason: z.string(),
  error: z.string(),
  paymentItem: adhocManifestItem,
});

export const createAdhocPaymentResponseSuccessSchema = z.object({
  message: z.string().nullable().optional(),
  failedPaymentItems: failedAdHocCreateSchema.array().nullable(),
  paymentManifest: createAdhocPaymentSchema,
  claimableEntries: z.array(claimableManualPaymentSchema).nullable(),
});
export const unsupportedCurrenciesErrorSchema = z.object({
  unsupportedCurrencies: z.array(z.string()).nullable().optional(),
  supportedCurrencies: z.array(z.string()).nullable().optional(),
});

export const createAdhocPaymentResponseFailureSchema = z.union([
  z.object({
    message: z.string().nullable().optional(),
  }),
  z
    .object({
      error: z.string().nullable().optional().or(z.any()),
    })
    .or(unsupportedCurrenciesErrorSchema),
]);

// used in return type from Treasury when querying for adhoc records
export const adhocRecordSchema = z.object({
  payment: adhocPaymentAction,
  manifest: z.record(
    z.string(),
    z.record(
      z.string(),
      z.union([
        z.object({
          userPayment: userRewardsSchema,
          paymentRecords: z.array(paymentRecordSchema),
        }),
        manualPaymentItemDB,
      ]),
    ),
  ),
});

export const adhocRecordsSchema = z.record(z.string(), adhocRecordSchema);
export type FailedAdHocCreate = z.infer<typeof failedAdHocCreateSchema>;
export type CreateAdhocPaymentResponseSuccess = z.infer<typeof createAdhocPaymentResponseSuccessSchema>;
export type CreateAdhocPaymentResponseFailure = z.infer<typeof createAdhocPaymentResponseFailureSchema>;
export type AdhocRecord = z.infer<typeof adhocRecordSchema>;
export type AdhocRecords = z.infer<typeof adhocRecordsSchema>;
