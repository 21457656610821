import { z } from "zod";
import { generateRandomName } from "../utils/random-names";
import { reservedWords } from "./utils";

export const tableConfigSchema = z.object({
  protocol: z.string().optional(),
  tableName: z.string().optional(),
  description: z.string().optional(),
  refreshOption: z.enum(["static", "incremental"]).default("static"),
  uniqueKeys: z.array(z.string()).nullish(),
  statement: z.string().nullish(),
  isTableNameUnique: z.boolean().nullish(),
  columns: z.array(z.object({ name: z.string(), type: z.string(), description: z.string() })).nullish(),
  lastCompassId: z.string().nullish(),
  isStatementVerified: z.boolean().nullish(),
  snowflakeQueryId: z.string().nullish(),
});

export const validColumnNameSchema = z
  .string()
  .regex(
    /^[a-zA-Z][a-zA-Z0-9_]*$/,
    "Column name must start with a letter, and contain only letters, numbers, and underscores",
  )
  .refine((name) => /[a-zA-Z]/.test(name), "Column name must contain at least one letter")
  .refine(
    (name) => !reservedWords.some((word) => word.toLowerCase() === name.toLowerCase()),
    "Column name cannot be a reserved keyword",
  );

export const validConfigSchema = z.object({
  protocol: z.string().min(3),
  tableName: z.string().min(3),
  description: z.string().min(3),
  refreshOption: z.enum(["static", "incremental"]),
  uniqueKeys: z.array(z.string().min(1)).min(1),
  statement: z.string().min(3),
  isTableNameUnique: z.literal(true),
  columns: z
    .array(
      z.object({
        name: validColumnNameSchema,
        type: z.string(),
        description: z.string(),
      }),
    )
    .min(1),
  isStatementVerified: z.literal(true),
});

export type TableConfig = z.infer<typeof tableConfigSchema>;

export const tableStatusSchema = z.object({
  STATUS: z.enum(["FAILED", "PENDING", "SUCCESS", "VALID", "INVALID", "NOT_APPLICABLE"]).default("PENDING"),
  FULL_REFRESH_STATUS: z
    .enum(["FAILED", "PENDING", "SUCCESS", "VALID", "INVALID", "NOT_APPLICABLE"])
    .default("PENDING"),
  INCREMENTAL_STATUS: z.enum(["FAILED", "PENDING", "SUCCESS", "VALID", "INVALID", "NOT_APPLICABLE"]).default("PENDING"),
  UNIQUE_KEY_STATUS: z.enum(["FAILED", "PENDING", "SUCCESS", "VALID", "INVALID", "NOT_APPLICABLE"]).default("PENDING"),
  ERROR_MESSAGE: z.string().nullable().optional(),
  FULL_REFRESH_ERROR: z.string().nullable().optional(),
  INCREMENTAL_ERROR: z.string().nullable().optional(),
  UNIQUE_KEY_ERROR: z.string().nullable().optional(),
});
// Base schema for Table
export const schema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  statement: z.string(),
  createdAt: z.date().default(new Date()),
  updatedAt: z.date().default(new Date()),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  profileId: z.string().uuid(),
  lastQueryRunId: z.string().uuid().nullable().optional(),
  lastCompassId: z.string().nullable().optional(),
  lastSavedAt: z.date().nullable().optional(),
  parentId: z.string().uuid().nullable().optional(),
  published: z.boolean().default(false),
  domainType: z.literal("Table").default("Table").optional(),
  draftConfig: tableConfigSchema.nullable().transform((val) => val ?? ({ refreshOption: "static" } as TableConfig)),
  publishedConfig: tableConfigSchema.nullish(),
  validationResults: tableStatusSchema.nullish(),
  lastPublishedAt: z.coerce.date().nullish(),
  status: z
    .enum(["draft", "pending", "invalid", "published", "editing", "failed", "valid"])
    .nullable()
    .default("draft"),
  latestRegistrationId: z.string().uuid().nullish(),
});

// Schema for creating a new Table
export const newSchema = schema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  createdById: true,
  updatedById: true,
  latestRegistrationId: true,
});

// Schema for updating a Table
export const updateSchema = schema
  .omit({
    createdAt: true,
    updatedAt: true,
    createdById: true,
    updatedById: true,
    profileId: true,
  })
  .partial();

// Schema for internal updates
export const updateSchemaInternal = schema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    createdById: true,
    updatedById: true,
  })
  .partial();

// Type definitions
export type Table = z.infer<typeof schema>;
export type TableNew = z.infer<typeof newSchema>;
export type TableUpdate = z.infer<typeof updateSchema>;
export type TableUpdateInternal = z.infer<typeof updateSchemaInternal>;

// Helper function to create a new Table
export const createTableNew = (payload: Partial<TableNew>): TableNew => {
  return {
    title: payload.title || generateRandomName(),
    statement: payload.statement || "",
    profileId: payload.profileId || "",
    parentId: payload.parentId || null,
    published: payload.published ?? false,
    draftConfig: {
      protocol: payload.draftConfig?.protocol || "",
      tableName: payload.draftConfig?.tableName || "",
      description: payload.draftConfig?.description || "",
      refreshOption: payload.draftConfig?.refreshOption || "static",
      uniqueKeys: payload.draftConfig?.uniqueKeys || [],
      isStatementVerified: payload.draftConfig?.isStatementVerified ?? false,
    },
    status: "draft",
  };
};

// Repository interface
export interface TableRepository {
  find: (id: string) => Promise<Table | null>;
  findMany: () => Promise<Table[]>;
  create: (table: TableNew, userId: string) => Promise<Table>;
  update: (id: string, table: TableUpdate, userId: string) => Promise<Table>;
  updateInternal: (id: string, table: TableUpdateInternal) => Promise<Table>;
  delete: (id: string) => Promise<void>;
  checkUniqueTableName: (tableName: string, schema: string) => Promise<boolean>;
}

export type TableStatus = z.infer<typeof tableStatusSchema>;

export interface SnowflakeCommunityTable {
  QUERY_ID: string;
  QUERY_STRING: string;
  QUERIES: {
    delete_overlapping: string;
    drop_temp: string;
    full_refresh: string;
    incremental: string;
    insert_new: string;
  };
  UNIQUE_KEY: string;
  SCHEMA_NAME: string;
  TABLE_NAME: string;
  TABLE_METADATA?: TableMetadata;
  USER_METADATA?: UserMetadata;
  INCREMENTAL: boolean;
  STATUS: TableStatus["STATUS"];
  FULL_REFRESH_STATUS: TableStatus["FULL_REFRESH_STATUS"];
  INCREMENTAL_STATUS: TableStatus["INCREMENTAL_STATUS"];
  UNIQUE_KEY_STATUS: TableStatus["UNIQUE_KEY_STATUS"];
  ERROR_MESSAGE: string | null;
  FULL_REFRESH_ERROR: string | null;
  INCREMENTAL_ERROR: string | null;
  FULL_RUNTIME_SECONDS: number;
  INCR_RUNTIME_SECONDS: number;
  UNIQUE_KEY_DUPLICATE_COUNT: number;
  LAST_REFRESH_TIME: Date;
  CREATED_AT: Date;
  QUERY_REGISTRY_ID: string;
}

export type TableMetadata = {
  description?: string;
  statement?: string;
  columns?: { name: string; type: string; description: string }[];
  snowflakeQueryId?: string;
};

export type UserMetadata = {
  user_id?: string;
  user_avatar?: string;
  username?: string;
};
