import { z } from "zod";

export const providerSchema = z.enum(["anthropic", "openai", "google", "deepseek", "groq", "litellm"]);
export const modelSchema = z.string();

export const aiAgentIdSchema = z.string().uuid();

// All the information needed to create an AI agent
export const createAiAgentSchema = z.object({
  name: z.string().min(3),
  slug: z.string().min(3),
  description: z.string().nullable(),
  authorId: z.string().uuid(),
  systemPrompt: z.string().min(3),
  model: modelSchema,
  provider: providerSchema,
  configuration: z.object({}).optional(),
});

// Updating an AI agent is the same as creating an AI agent,
// but needs to specify the id.
// The service layer assumes that any omitted fields
// should be pulled from the latest version of the agent.
// That includes non-official versions.
export const updateAiAgentSchema = z.object({
  agentId: aiAgentIdSchema,
  authorId: z.string().uuid(),
  name: z.string().optional(),
  slug: z.string().optional(),
  description: z.string().optional(),
  systemPrompt: z.string().optional(),
  model: modelSchema.optional(),
  provider: providerSchema.optional(),
  configuration: z.object({}).default({}),
  publish: z.boolean().optional(),
});

// Creating new versions of an AI agent only happens directly
// against the agent repository during the update flow.
export const createAiAgentVersionSchema = createAiAgentSchema.extend({
  agentId: aiAgentIdSchema,
  version: z.number(),
  official: z.boolean(),
});

// AI agents themselves are just all the information needed to create
// an AI agent, plus the id and creation date.
export const aiAgentSchema = createAiAgentSchema.extend({
  id: aiAgentIdSchema,
  creationDate: z.date(),
  version: z.number(),
  versionId: z.string().uuid(),
  official: z.boolean(),
  versionCreationDate: z.date(),
});

export const usageSchema = z.object({
  input_tokens: z.number(),
  output_tokens: z.number(),
});

export const createUserAiUsageSchema = z.object({
  agentSlug: z.string(),
  version: z.number(),
  userId: z.string().uuid(),
  usage: usageSchema,
  startedAt: z.date(),
  endedAt: z.date(),
  status: z.string(),
});

export const aiAgentVersionSchema = z.object({
  id: z.string().uuid(),
  authorId: z.string().uuid(),
  version: z.number(),
  official: z.boolean(),
  createdAt: z.date(),
  authorAvatar: z.string().nullish(),
  authorUsername: z.string(),
});

export interface Metrics {
  timer: {
    startTimer: (labels: Record<string, string | number>) => (labels?: Record<string, string | number>) => number;
  };
  tokenUsage: {
    inc: (labels: Record<string, string | number>, value?: number) => void;
  };
  labels: Record<string, string | number>;
}

export const playgroundExecutionInputSchema = z.object({
  systemPrompt: z.string(),
  model: modelSchema,
  messages: z.array(z.object({ role: z.enum(["user", "assistant"]), content: z.string() })),
  provider: providerSchema,
});

export const officialExecutionInputSchema = z.object({
  id: aiAgentIdSchema.optional(),
  slug: z.string().optional(),
  messages: z.array(z.object({ role: z.enum(["user", "assistant"]), content: z.string() })),
  variables: z.record(z.string(), z.string()).optional(),
  chatId: z.string().uuid().optional(),
});

export const streamConfigOverridesSchema = z.object({
  provider: providerSchema.optional(),
  model: modelSchema.optional(),
  systemPrompt: z.string().optional(),
});

export type StreamConfig = z.infer<typeof streamConfigOverridesSchema>;

export const streamExecutionInputSchema = z.object({
  slug: z.string(),
  messages: z.array(z.object({ role: z.enum(["user", "assistant"]), content: z.string() })),
  data: z
    .object({
      configOverrides: streamConfigOverridesSchema.optional(),
      variables: z.record(z.string(), z.string()).optional(),
      chatId: z.string().uuid().optional(),
    })
    .optional(),
});

export type StreamExecutionInput = z.infer<typeof streamExecutionInputSchema>;
export const aiUsageRecordSchema = usageSchema.extend({
  agentSlug: z.string(),
  version: z.number(),
  provider: providerSchema,
  model: modelSchema,
  startedAt: z.date(),
  endedAt: z.date(),
  status: z.string().optional(),
});

export const chatMessageSchema = z.object({
  role: z.enum(["system", "user", "assistant"]),
  content: z.string(),
});

export type ChatMessage = z.infer<typeof chatMessageSchema>;

export type PlaygroundExecutionInput = z.infer<typeof playgroundExecutionInputSchema>;
export type OfficialExecutionInput = z.infer<typeof officialExecutionInputSchema>;
export type AiAgentModel = z.infer<typeof modelSchema>;
export type AiAgentProvider = z.infer<typeof providerSchema>;
export type AiAgentVersion = z.infer<typeof aiAgentVersionSchema>;
export type AiAgentId = z.infer<typeof aiAgentIdSchema>;
export type CreateAiAgent = z.infer<typeof createAiAgentSchema>;
export type UpdateAiAgent = z.infer<typeof updateAiAgentSchema>;
export type CreateAiAgentVersion = z.infer<typeof createAiAgentVersionSchema>;
export type AiAgent = z.infer<typeof aiAgentSchema>;
export type Usage = z.infer<typeof usageSchema>;
export type CreateUserAiUsage = z.infer<typeof createUserAiUsageSchema>;
export type AiUsageRecord = z.infer<typeof aiUsageRecordSchema>;

export const userRateLimitSchema = z.object({
  userId: z.string().uuid(),
  burstLimit: z.number().nullable(),
  dailyTokenLimit: z.number().nullable(),
  createdAt: z.date(),
  updatedAt: z.date().nullable(),
});

export type UserRateLimit = z.infer<typeof userRateLimitSchema>;

export const createUserRateLimitSchema = z.object({
  userId: z.string().uuid(),
  burstLimit: z.number().nullable(),
  dailyTokenLimit: z.number().nullable(),
});

export type CreateUserRateLimit = z.infer<typeof createUserRateLimitSchema>;
