import { z } from "zod";
import type { PartnerTierWithDetails } from "../budget/tier";
import type { PartnerDeal } from "../deal";
import type { PartnerOffering } from "../partner-offering";
import type { Identity } from "../utils/identity";
import type { ZodSchema } from "../utils/zod";
import type { PartnerChainWithAssociations, PartnerChainWithChain } from "./partner-chain";

export type Partner = {
  id: string;
  slug: string;
  displayName: string;
  description: string | null;
  createdAt: Date;
  updatedAt: Date;
  updatedById: string | null;
  createdById: string | null;
  ecoLeadId: string | null;
  tier?: PartnerTierWithDetails | null;
  fundedByPartnerId?: string;
  aiOutcomesPrompt: string | null;
};

export type PartnerWithChains = Identity<
  Partner & {
    partnerChains: PartnerChainWithAssociations[];
  }
>;

export type PartnerWithAllAssociations = Identity<
  Omit<Partner, "tier"> & {
    partnerChains: PartnerChainWithChain[];
    offerings: PartnerOffering[];
    deals: PartnerDeal[];
    tier: string | undefined | null;
  }
>;

export type PartnerNew = Pick<Partner, "displayName" | "slug"> & {
  ecoLeadId?: string | undefined | null;
  description?: string | null;
  tierId?: string | null;
};

export const partnerSchema = z.object({
  id: z.string().uuid(),
  displayName: z.string(),
  slug: z.string(),
  description: z.string().nullable().optional(),
  tierId: z.string().uuid(),
  ecoLeadId: z.string().uuid().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  aiOutcomesPrompt: z.string().nullish(),
}) satisfies ZodSchema<PartnerNew>;

export const partnerCreateSchema = z.object({
  displayName: z.string(),
  slug: z.string(),
  tierId: z.string().uuid().nullable().optional(),
  ecoLeadId: z.string().uuid().nullable().optional(),
  description: z.string().nullable().optional(),
  aiOutcomesPrompt: z.string().nullish(),
}) satisfies ZodSchema<PartnerNew>;

export type PartnerUpdate = Pick<Partner, "id" | "displayName" | "slug"> & {
  ecoLeadId?: string | null;
  tierId?: string | null;
  aiOutcomesPrompt?: string | null;
};

export const partnerUpdateSchema = z.object({
  id: z.string().uuid(),
  displayName: z.string(),
  slug: z.string().min(1),
  tierId: z.string().uuid().nullable().optional(),
  ecoLeadId: z.string().uuid().nullable().optional(),
  description: z.string().nullable().optional(),
  aiOutcomesPrompt: z.string().nullish(),
}) satisfies ZodSchema<PartnerUpdate>;
