import type { DataExplorerNode } from "../data-schema";
import type { SnowflakeCommunityTable } from "./table";

export const convertCommunityTableToDataExplorerNode = (tables: SnowflakeCommunityTable[]): DataExplorerNode => {
  // Create the root database node for "community"
  const databaseNode: DataExplorerNode = {
    id: "community",
    name: "community",
    type: "database",
    addedAt: new Date(),
    accessLevel: "public",
    children: [],
    comment: "",
    isDeprecating: false,
    deprecationAt: new Date(),
  };

  // Group tables by schema
  const schemaMap = new Map<string, SnowflakeCommunityTable[]>();

  for (const table of tables) {
    if (!table.SCHEMA_NAME) continue;

    const schemaName = table.SCHEMA_NAME;
    const existing = schemaMap.get(schemaName) || [];
    schemaMap.set(schemaName, [...existing, table]);
  }

  // Create schema nodes with their tables
  databaseNode.children = Array.from(schemaMap.entries())
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([schemaName, schemaTables]) => {
      const schemaNode: DataExplorerNode = {
        id: `community.${schemaName}`,
        name: schemaName,
        type: "schema",
        addedAt: new Date(),
        accessLevel: "public",
        children: [],
        comment: "",
        isDeprecating: false,
        deprecationAt: new Date("2099-12-31"),
      };

      // Create table nodes - sorted alphabetically
      schemaNode.children = schemaTables
        .filter((table) => table.TABLE_NAME)
        .sort((a, b) => a.TABLE_NAME.localeCompare(b.TABLE_NAME))
        .map((table) => {
          const tableNode: DataExplorerNode = {
            id: `community.${schemaName}.${table.TABLE_NAME}`,
            name: table.TABLE_NAME,
            type: "table",
            addedAt: table.CREATED_AT,
            accessLevel: "public",
            comment: table.TABLE_METADATA?.description ?? "",
            children: [],
            isDeprecating: false,
            deprecationAt: new Date("2099-12-31"),
            statement: table.TABLE_METADATA?.statement ?? "",
            user_avatar: table.USER_METADATA?.user_avatar ?? "",
            username: table.USER_METADATA?.username ?? "",
          };

          // Add columns as children if they exist in TABLE_METADATA
          if (table.TABLE_METADATA?.columns?.length) {
            tableNode.children = table.TABLE_METADATA.columns.map((column) => ({
              id: `community.${schemaName}.${table.TABLE_NAME}.${column.name}`,
              name: column.name,
              type: "column",
              addedAt: table.CREATED_AT,
              accessLevel: "public",
              comment: column.description ?? "",
              children: [],
              isDeprecating: false,
              deprecationAt: new Date("2099-12-31"),
              dataType: column.type,
            }));
          }

          return tableNode;
        });

      return schemaNode;
    });

  return databaseNode;
};

export const reservedWords = [
  "ACCOUNT",
  "ALL",
  "ALTER",
  "AND",
  "ANY",
  "AS",
  "BETWEEN",
  "BY",
  "CASE",
  "CAST",
  "CHECK",
  "COLUMN",
  "CONNECT",
  "CONNECTION",
  "CONSTRAINT",
  "CREATE",
  "CROSS",
  "CURRENT",
  "CURRENT_DATE",
  "CURRENT_TIME",
  "CURRENT_TIMESTAMP",
  "CURRENT_USER",
  "DATABASE",
  "DELETE",
  "DISTINCT",
  "DROP",
  "ELSE",
  "EXISTS",
  "FALSE",
  "FOLLOWING",
  "FOR",
  "FROM",
  "FULL",
  "GRANT",
  "GROUP",
  "GSCLUSTER",
  "HAVING",
  "ILIKE",
  "IN",
  "INCREMENT",
  "INNER",
  "INSERT",
  "INTERSECT",
  "INTO",
  "IS",
  "JOIN",
  "LATERAL",
  "LEFT",
  "LIKE",
  "LOCALTIME",
  "LOCALTIMESTAMP",
  "MINUS",
  "NATURAL",
  "NOT",
  "NULL",
  "OF",
  "ON",
  "OR",
  "ORDER",
  "ORGANIZATION",
  "QUALIFY",
  "REGEXP",
  "REVOKE",
  "RIGHT",
  "RLIKE",
  "ROW",
  "ROWS",
  "SAMPLE",
  "SCHEMA",
  "SELECT",
  "SET",
  "SOME",
  "START",
  "TABLE",
  "TABLESAMPLE",
  "THEN",
  "TO",
  "TRIGGER",
  "TRUE",
  "TRY_CAST",
  "UNION",
  "UNIQUE",
  "UPDATE",
  "USING",
  "VALUES",
  "VIEW",
  "WHEN",
  "WHENEVER",
  "WHERE",
  "WITH",
];
