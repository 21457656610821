import { z } from "zod";

const taxDocumentationStatusSchema = z.enum(["INVALID", "VALID", "PENDING", "NOT_STARTED"]);

export type TaxDocumentationStatus = z.TypeOf<typeof taxDocumentationStatusSchema>;

export const taxDocumentationSchema = z.object({
  status: taxDocumentationStatusSchema,
});

export type TaxDocumentation = z.TypeOf<typeof taxDocumentationSchema>;

export const taxDocumentSchema = z.object({
  id: z.string(),
  year: z.number(),
  type: z.enum([
    "GAIN_LOSS_SUMMARY",
    "GAIN_LOSS_SUMMARY_PDF",
    "1042_S",
    "1099_B",
    "1099_DIV",
    "1099_INT",
    "1099_K",
    "1099_MISC",
    "1099_NEC",
    "1099_R",
    "5498",
    "RMD_STATEMENT",
    "TRANSACTION_SUMMARY",
    "TRANSACTION_SUMMARY_PDF",
    "UK_GAIN_LOSS_SUMMARY",
    "DAC7",
  ]),
  is_filed: z.boolean(),
  created_date: z.string(),
  revision: z.number(),
  revision_type: z.enum(["ORIGINAL", "CORRECTION", "VOID"]),
  url: z.string(),
});

export type TaxDocument = z.TypeOf<typeof taxDocumentSchema>;
