import { z } from "zod";
import type { Decimal } from "../utils/zod";
import { type ZodSchema, decimalSchema } from "../utils/zod";

export type DelegationDailyArchive = {
  id: string;
  delegationId: string;
  aprCurrent: Decimal | null;
  validatorCommissionCurrent: Decimal | null;
  tokenPriceCurrent: Decimal;
  tokenAmountCurrent: Decimal;
  estValueUsd: Decimal;
  createdAt: Date;
  dateOfCreation: string;
  dailyArchiveId: string;
};

export type DelegationDailyArchiveNew = Omit<DelegationDailyArchive, "id" | "createdAt">;

export const delegationDailyArchiveSchema = z.object({
  id: z.string().uuid(),
  delegationId: z.string().uuid(),
  aprCurrent: decimalSchema.nullable(),
  validatorCommissionCurrent: decimalSchema.nullable(),
  tokenPriceCurrent: decimalSchema,
  tokenAmountCurrent: decimalSchema,
  estValueUsd: decimalSchema,
  createdAt: z.coerce.date(),
  dateOfCreation: z.string(),
  dailyArchiveId: z.string().uuid(),
}) satisfies ZodSchema<DelegationDailyArchive>;

export const delegationDailyArchiveCreateSchema = delegationDailyArchiveSchema.omit({
  id: true,
  createdAt: true,
}) satisfies ZodSchema<DelegationDailyArchiveNew>;
