import { z } from "zod";
import type { Chain } from "../chain";
import { chainSchema } from "../chain";
import type { Identity } from "../utils/identity";
import type { ZodSchema } from "../utils/zod";

export type Token = {
  id: string;
  name: string;
  symbol: string;
  chainId: string;
  fireblocksKey: string | null;
  precision: number;
  geckoId: string | null;
  stakingRewardsId: string | null;
  contractAddress: string | null;
  thumbnailUrl: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type TokenWithChain = Token & {
  chain: Chain;
};

export type TokenWithAssociations = Identity<
  Token & {
    chain: Chain;
  }
>;

export type TokenNew = Identity<
  Pick<Token, "name" | "symbol" | "chainId" | "precision"> & {
    fireblocksKey?: string | null;
    geckoId?: string | null;
    contractAddress?: string | null;
    stakingRewardsId?: string | null;
  }
>;

export const tokenSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  symbol: z.string().min(1),
  chainId: z.string().uuid(),
  precision: z.number().int().positive(),
  fireblocksKey: z.string().nullable(),
  geckoId: z.string().nullable(),
  contractAddress: z.string().nullable(),
  stakingRewardsId: z.string().nullable(),
  thumbnailUrl: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
}) satisfies ZodSchema<Token>;

export const tokenWithAssociationsSchema = tokenSchema.extend({
  chain: chainSchema,
}) satisfies ZodSchema<TokenWithAssociations>;

export const tokenCreateSchema = z.object({
  name: z.string().min(1),
  symbol: z.string().min(1),
  chainId: z.string().uuid(),
  precision: z.number().int().positive(),
  fireblocksKey: z.string().nullable().optional(),
  geckoId: z.string().nullable().optional(),
  contractAddress: z.string().nullable().optional(),
  stakingRewardsId: z.string().nullable().optional(),
}) satisfies ZodSchema<TokenNew>;

export type TokenUpdate = Identity<TokenNew & { id: string }>;

export const tokenUpdateSchema = tokenCreateSchema.extend({
  id: z.string().uuid(),
}) satisfies ZodSchema<TokenUpdate>;
