import { z } from "zod";

export interface ResourceSummary {
  id: string;
  resourceId: string;
  resourceType: "dashboard" | "query" | "visualization";
  summaryType: "default"; // More summary types can be added in the future
  summary: string;
  vector: number[];
  vectorModel?: string | null;
  hidden?: boolean | null;
}

export const resourceSummarySchema = z.object({
  id: z.string(),
  resourceId: z.string(),
  resourceType: z.enum(["dashboard", "query", "visualization"]),
  summaryType: z.enum(["default"]),
  summary: z.string(),
  vector: z.array(z.number()),
  vectorModel: z.string().nullish(),
  hidden: z.boolean().nullish().default(false),
});
