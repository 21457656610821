import { z } from "zod";
import type { Partner } from "../partner";
import type { ZodSchema } from "../utils/zod";

export type PartnerOfferingType = "TOPN" | "QUESTS" | "DATA";
export const partnerOfferingTypesAsArray = ["TOPN", "QUESTS", "DATA"] as const;

export type PartnerOffering = {
  id: string;
  partnerId: string;
  createdAt: Date;
  updatedAt: Date;
  type: PartnerOfferingType;
  partner?: Partner;
};

export const schema = z.object({
  id: z.string().uuid(),
  partnerId: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  type: z.enum(partnerOfferingTypesAsArray),
});

export type PartnerOfferingNew = Pick<PartnerOffering, "partnerId" | "type">;

export const partnerOfferingNewSchema = z.object({
  partnerId: z.string().uuid(),
  type: z.enum(partnerOfferingTypesAsArray),
}) satisfies ZodSchema<PartnerOfferingNew>;
