import { z } from "zod";
import { schema as areaChartSchema } from "./area";
import { schema as barChartSchema } from "./bar";
import { schema as bigNumberSchema } from "./big-number";
import { schema as donutSchema } from "./donut";
import { schema as legacyBarLineSchema } from "./legacy-bar-line";
import { schema as legendSchema } from "./legend";
import { schema as lineChartSchema } from "./line";
import { schema as scatterChartSchema } from "./scatter";

/*********************************************************************
 * Visualization Types
 *********************************************************************/
export const chartSchema = z.discriminatedUnion("type", [
  areaChartSchema,
  barChartSchema,
  lineChartSchema,
  scatterChartSchema,
  bigNumberSchema,
  donutSchema,
  legacyBarLineSchema,
]);

export type Chart = z.infer<typeof chartSchema>;

const date = z.preprocess((arg) => {
  if (typeof arg === "string" || arg instanceof Date) return new Date(arg);
}, z.date().default(new Date()));
export const legacyOptionsSchema = z.record(z.string(), z.any());

export const chartTypeSchema = z.union([
  z.literal("donut"),
  z.literal("big_number"),
  z.literal("area"),
  z.literal("line"),
  z.literal("bar"),
  z.literal("scatter"),
  z.literal("bar_line"),
]);

export const legacyChartTypeSchema = z.union([
  z.literal("donut"),
  z.literal("big_number"),
  z.literal("area"),
  z.literal("line2"),
  z.literal("bar2"),
  z.literal("scatter"),
  z.literal("multi"),
]);

export const schema = z.object({
  id: z.string(),
  legacyOptions: legacyOptionsSchema.default({}),
  legacyType: legacyChartTypeSchema.default("bar2"),
  chart: chartSchema.default(barChartSchema.parse({ type: "bar" })),
  version: z.enum(["1", "2", "3"]).default("2"),
  title: z.string().default("Untitled Chart"),
  legend: legendSchema.default({ show: true, position: "right" }),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  createdAt: date,
  updatedAt: date,
  chartType: chartTypeSchema.default("bar"),
  queryId: z.string().uuid(),
  profileId: z.string().uuid(),
  forkedFromId: z.string().uuid().nullable(),
  domainType: z.literal("Visualization").default("Visualization"),
});

const newSchemaPicked = schema.pick({
  chart: true,
  version: true,
  title: true,
  legend: true,
  queryId: true,
});

export const newSchema = newSchemaPicked.extend({
  forkedFromId: z.string().uuid().nullable().optional(),
});

export const updateSchema = schema.partial();

export type Visualization = z.infer<typeof schema>;
export type VisualizationNew = z.infer<typeof newSchema>;
export type VisualizationUpdate = z.infer<typeof updateSchema>;
export type VisualizationType = z.infer<typeof chartSchema>["type"];
export type VisualizationLegend = z.infer<typeof legendSchema>;
export type VisualizationLegacyOptions = z.infer<typeof legacyOptionsSchema>;
export type VisualizationLegacyType = z.infer<typeof legacyChartTypeSchema>;

// Utility transformers

export const serializeVisualization = (visualization: Visualization): SerializedVisualization => {
  return {
    ...visualization,
    createdAt:
      visualization.createdAt instanceof Date ? visualization.createdAt.toISOString() : visualization.createdAt,
    updatedAt:
      visualization.updatedAt instanceof Date ? visualization.updatedAt.toISOString() : visualization.updatedAt,
    // Add other specific fields to be serialized if any
  };
};

export const deserializeVisualization = (
  serializedVisualization: SerializedVisualization,
): DeserializedVisualization => {
  return {
    ...serializedVisualization,
    createdAt: new Date(serializedVisualization.createdAt),
    updatedAt: new Date(serializedVisualization.updatedAt),
    // Add other specific fields to be deserialized if any
  };
};

// Serialized and Deserialized Visualization Schemas
export const serializedVisualizationSchema = z.object({
  ...schema.shape,
  createdAt: z.string(),
  updatedAt: z.string(),
  // Add other fields to be serialized if any
});

export const deserializedVisualizationSchema = z.object({
  ...schema.shape,
  createdAt: z.date(),
  updatedAt: z.date(),
  // Add other fields to be deserialized if any
});

export type SerializedVisualization = z.infer<typeof serializedVisualizationSchema>;
export type DeserializedVisualization = z.infer<typeof deserializedVisualizationSchema>;
